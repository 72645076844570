import React from 'react'
import { graphql, Link } from "gatsby";
import { Container, Row, Col, Tab, Tabs } from "react-bootstrap";
import Layout from "../../components/Layout";
import Slider from "react-slick";
import postFeedback from "../../components/PostFeedback";
import SEO from "../../components/SEO";

class epressplatform extends React.Component {
    constructor(props) {
        super(props);
        this.increaseImageWidthRow1 = this.increaseImageWidthRow1.bind(this);
        this.increaseImageWidthRow2 = this.increaseImageWidthRow2.bind(this);
        this.increaseImageWidthRow3 = this.increaseImageWidthRow3.bind(this);
        this.increaseImageWidthRow4 = this.increaseImageWidthRow4.bind(this);
        this.increaseImageWidthRow5 = this.increaseImageWidthRow5.bind(this);
        this.increaseImageWidthRow6 = this.increaseImageWidthRow6.bind(this);
        this.increaseImageWidthRow7 = this.increaseImageWidthRow7.bind(this);
        this.increaseImageWidthRow8 = this.increaseImageWidthRow8.bind(this);
        this.increaseImageWidthRow9 = this.increaseImageWidthRow9.bind(this);
        this.hitButtonLiveDemoeSign = this.hitButtonLiveDemoeSign.bind(this);
        this.hitButtonPricingeSign = this.hitButtonPricingeSign.bind(this);
    }

    componentDidMount() {
        document.querySelector('.app-section-epressplatform').classList.add('sal-animate');
    }

    increaseImageWidthRow1() {
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-1 .app-epressplatform-row-1-col-left').classList.toggle('app-epressplatform-row-1-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-1 .app-epressplatform-row-1-col-right').classList.toggle('app-epressplatform-row-1-col-right-active');

        document.querySelector('.app-section-epressplatform .app-epressplatform-row-2 .app-epressplatform-row-2-col-left').classList.remove('app-epressplatform-row-2-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-2 .app-epressplatform-row-2-col-right').classList.remove('app-epressplatform-row-2-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-3 .app-epressplatform-row-3-col-left').classList.remove('app-epressplatform-row-3-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-3 .app-epressplatform-row-3-col-right').classList.remove('app-epressplatform-row-3-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-4 .app-epressplatform-row-4-col-left').classList.remove('app-epressplatform-row-4-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-4 .app-epressplatform-row-4-col-right').classList.remove('app-epressplatform-row-4-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-5 .app-epressplatform-row-5-col-left').classList.remove('app-epressplatform-row-5-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-5 .app-epressplatform-row-5-col-right').classList.remove('app-epressplatform-row-5-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-6 .app-epressplatform-row-6-col-left').classList.remove('app-epressplatform-row-6-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-6 .app-epressplatform-row-6-col-right').classList.remove('app-epressplatform-row-6-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-7 .app-epressplatform-row-7-col-left').classList.remove('app-epressplatform-row-7-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-7 .app-epressplatform-row-7-col-right').classList.remove('app-epressplatform-row-7-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-8 .app-epressplatform-row-8-col-left').classList.remove('app-epressplatform-row-8-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-8 .app-epressplatform-row-8-col-right').classList.remove('app-epressplatform-row-8-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-9 .app-epressplatform-row-9-col-left').classList.remove('app-epressplatform-row-9-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-9 .app-epressplatform-row-9-col-right').classList.remove('app-epressplatform-row-9-col-right-active');
    }

    increaseImageWidthRow2() {
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-2 .app-epressplatform-row-2-col-left').classList.toggle('app-epressplatform-row-2-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-2 .app-epressplatform-row-2-col-right').classList.toggle('app-epressplatform-row-2-col-right-active');

        document.querySelector('.app-section-epressplatform .app-epressplatform-row-1 .app-epressplatform-row-1-col-left').classList.remove('app-epressplatform-row-1-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-1 .app-epressplatform-row-1-col-right').classList.remove('app-epressplatform-row-1-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-3 .app-epressplatform-row-3-col-left').classList.remove('app-epressplatform-row-3-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-3 .app-epressplatform-row-3-col-right').classList.remove('app-epressplatform-row-3-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-4 .app-epressplatform-row-4-col-left').classList.remove('app-epressplatform-row-4-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-4 .app-epressplatform-row-4-col-right').classList.remove('app-epressplatform-row-4-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-5 .app-epressplatform-row-5-col-left').classList.remove('app-epressplatform-row-5-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-5 .app-epressplatform-row-5-col-right').classList.remove('app-epressplatform-row-5-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-6 .app-epressplatform-row-6-col-left').classList.remove('app-epressplatform-row-6-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-6 .app-epressplatform-row-6-col-right').classList.remove('app-epressplatform-row-6-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-7 .app-epressplatform-row-7-col-left').classList.remove('app-epressplatform-row-7-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-7 .app-epressplatform-row-7-col-right').classList.remove('app-epressplatform-row-7-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-8 .app-epressplatform-row-8-col-left').classList.remove('app-epressplatform-row-8-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-8 .app-epressplatform-row-8-col-right').classList.remove('app-epressplatform-row-8-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-9 .app-epressplatform-row-9-col-left').classList.remove('app-epressplatform-row-9-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-9 .app-epressplatform-row-9-col-right').classList.remove('app-epressplatform-row-9-col-right-active');
    }

    increaseImageWidthRow3() {
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-3 .app-epressplatform-row-3-col-left').classList.toggle('app-epressplatform-row-3-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-3 .app-epressplatform-row-3-col-right').classList.toggle('app-epressplatform-row-3-col-right-active');

        document.querySelector('.app-section-epressplatform .app-epressplatform-row-1 .app-epressplatform-row-1-col-left').classList.remove('app-epressplatform-row-1-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-1 .app-epressplatform-row-1-col-right').classList.remove('app-epressplatform-row-1-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-2 .app-epressplatform-row-2-col-left').classList.remove('app-epressplatform-row-2-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-2 .app-epressplatform-row-2-col-right').classList.remove('app-epressplatform-row-2-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-4 .app-epressplatform-row-4-col-left').classList.remove('app-epressplatform-row-4-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-4 .app-epressplatform-row-4-col-right').classList.remove('app-epressplatform-row-4-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-5 .app-epressplatform-row-5-col-left').classList.remove('app-epressplatform-row-5-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-5 .app-epressplatform-row-5-col-right').classList.remove('app-epressplatform-row-5-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-6 .app-epressplatform-row-6-col-left').classList.remove('app-epressplatform-row-6-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-6 .app-epressplatform-row-6-col-right').classList.remove('app-epressplatform-row-6-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-7 .app-epressplatform-row-7-col-left').classList.remove('app-epressplatform-row-7-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-7 .app-epressplatform-row-7-col-right').classList.remove('app-epressplatform-row-7-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-8 .app-epressplatform-row-8-col-left').classList.remove('app-epressplatform-row-8-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-8 .app-epressplatform-row-8-col-right').classList.remove('app-epressplatform-row-8-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-9 .app-epressplatform-row-9-col-left').classList.remove('app-epressplatform-row-9-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-9 .app-epressplatform-row-9-col-right').classList.remove('app-epressplatform-row-9-col-right-active');
    }

    increaseImageWidthRow4() {
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-4 .app-epressplatform-row-4-col-left').classList.toggle('app-epressplatform-row-4-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-4 .app-epressplatform-row-4-col-right').classList.toggle('app-epressplatform-row-4-col-right-active');

        document.querySelector('.app-section-epressplatform .app-epressplatform-row-1 .app-epressplatform-row-1-col-left').classList.remove('app-epressplatform-row-1-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-1 .app-epressplatform-row-1-col-right').classList.remove('app-epressplatform-row-1-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-2 .app-epressplatform-row-2-col-left').classList.remove('app-epressplatform-row-2-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-2 .app-epressplatform-row-2-col-right').classList.remove('app-epressplatform-row-2-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-3 .app-epressplatform-row-3-col-left').classList.remove('app-epressplatform-row-3-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-3 .app-epressplatform-row-3-col-right').classList.remove('app-epressplatform-row-3-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-5 .app-epressplatform-row-5-col-left').classList.remove('app-epressplatform-row-5-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-5 .app-epressplatform-row-5-col-right').classList.remove('app-epressplatform-row-5-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-6 .app-epressplatform-row-6-col-left').classList.remove('app-epressplatform-row-6-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-6 .app-epressplatform-row-6-col-right').classList.remove('app-epressplatform-row-6-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-7 .app-epressplatform-row-7-col-left').classList.remove('app-epressplatform-row-7-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-7 .app-epressplatform-row-7-col-right').classList.remove('app-epressplatform-row-7-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-8 .app-epressplatform-row-8-col-left').classList.remove('app-epressplatform-row-8-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-8 .app-epressplatform-row-8-col-right').classList.remove('app-epressplatform-row-8-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-9 .app-epressplatform-row-9-col-left').classList.remove('app-epressplatform-row-9-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-9 .app-epressplatform-row-9-col-right').classList.remove('app-epressplatform-row-9-col-right-active');
    }

    increaseImageWidthRow5() {
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-5 .app-epressplatform-row-5-col-left').classList.toggle('app-epressplatform-row-5-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-5 .app-epressplatform-row-5-col-right').classList.toggle('app-epressplatform-row-5-col-right-active');

        document.querySelector('.app-section-epressplatform .app-epressplatform-row-1 .app-epressplatform-row-1-col-left').classList.remove('app-epressplatform-row-1-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-1 .app-epressplatform-row-1-col-right').classList.remove('app-epressplatform-row-1-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-2 .app-epressplatform-row-2-col-left').classList.remove('app-epressplatform-row-2-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-2 .app-epressplatform-row-2-col-right').classList.remove('app-epressplatform-row-2-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-3 .app-epressplatform-row-3-col-left').classList.remove('app-epressplatform-row-3-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-3 .app-epressplatform-row-3-col-right').classList.remove('app-epressplatform-row-3-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-4 .app-epressplatform-row-4-col-left').classList.remove('app-epressplatform-row-4-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-4 .app-epressplatform-row-4-col-right').classList.remove('app-epressplatform-row-4-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-6 .app-epressplatform-row-6-col-left').classList.remove('app-epressplatform-row-6-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-6 .app-epressplatform-row-6-col-right').classList.remove('app-epressplatform-row-6-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-7 .app-epressplatform-row-7-col-left').classList.remove('app-epressplatform-row-7-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-7 .app-epressplatform-row-7-col-right').classList.remove('app-epressplatform-row-7-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-8 .app-epressplatform-row-8-col-left').classList.remove('app-epressplatform-row-8-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-8 .app-epressplatform-row-8-col-right').classList.remove('app-epressplatform-row-8-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-9 .app-epressplatform-row-9-col-left').classList.remove('app-epressplatform-row-9-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-9 .app-epressplatform-row-9-col-right').classList.remove('app-epressplatform-row-9-col-right-active');
    }

    increaseImageWidthRow6() {
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-6 .app-epressplatform-row-6-col-left').classList.toggle('app-epressplatform-row-6-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-6 .app-epressplatform-row-6-col-right').classList.toggle('app-epressplatform-row-6-col-right-active');

        document.querySelector('.app-section-epressplatform .app-epressplatform-row-1 .app-epressplatform-row-1-col-left').classList.remove('app-epressplatform-row-1-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-1 .app-epressplatform-row-1-col-right').classList.remove('app-epressplatform-row-1-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-2 .app-epressplatform-row-2-col-left').classList.remove('app-epressplatform-row-2-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-2 .app-epressplatform-row-2-col-right').classList.remove('app-epressplatform-row-2-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-3 .app-epressplatform-row-3-col-left').classList.remove('app-epressplatform-row-3-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-3 .app-epressplatform-row-3-col-right').classList.remove('app-epressplatform-row-3-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-4 .app-epressplatform-row-4-col-left').classList.remove('app-epressplatform-row-4-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-4 .app-epressplatform-row-4-col-right').classList.remove('app-epressplatform-row-4-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-5 .app-epressplatform-row-5-col-left').classList.remove('app-epressplatform-row-5-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-5 .app-epressplatform-row-5-col-right').classList.remove('app-epressplatform-row-5-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-7 .app-epressplatform-row-7-col-left').classList.remove('app-epressplatform-row-7-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-7 .app-epressplatform-row-7-col-right').classList.remove('app-epressplatform-row-7-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-8 .app-epressplatform-row-8-col-left').classList.remove('app-epressplatform-row-8-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-8 .app-epressplatform-row-8-col-right').classList.remove('app-epressplatform-row-8-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-9 .app-epressplatform-row-9-col-left').classList.remove('app-epressplatform-row-9-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-9 .app-epressplatform-row-9-col-right').classList.remove('app-epressplatform-row-9-col-right-active');
    }

    increaseImageWidthRow7() {
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-7 .app-epressplatform-row-7-col-left').classList.toggle('app-epressplatform-row-7-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-7 .app-epressplatform-row-7-col-right').classList.toggle('app-epressplatform-row-7-col-right-active');

        document.querySelector('.app-section-epressplatform .app-epressplatform-row-1 .app-epressplatform-row-1-col-left').classList.remove('app-epressplatform-row-1-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-1 .app-epressplatform-row-1-col-right').classList.remove('app-epressplatform-row-1-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-2 .app-epressplatform-row-2-col-left').classList.remove('app-epressplatform-row-2-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-2 .app-epressplatform-row-2-col-right').classList.remove('app-epressplatform-row-2-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-3 .app-epressplatform-row-3-col-left').classList.remove('app-epressplatform-row-3-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-3 .app-epressplatform-row-3-col-right').classList.remove('app-epressplatform-row-3-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-4 .app-epressplatform-row-4-col-left').classList.remove('app-epressplatform-row-4-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-4 .app-epressplatform-row-4-col-right').classList.remove('app-epressplatform-row-4-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-5 .app-epressplatform-row-5-col-left').classList.remove('app-epressplatform-row-5-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-5 .app-epressplatform-row-5-col-right').classList.remove('app-epressplatform-row-5-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-6 .app-epressplatform-row-6-col-left').classList.remove('app-epressplatform-row-6-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-6 .app-epressplatform-row-6-col-right').classList.remove('app-epressplatform-row-6-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-8 .app-epressplatform-row-8-col-left').classList.remove('app-epressplatform-row-8-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-8 .app-epressplatform-row-8-col-right').classList.remove('app-epressplatform-row-8-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-9 .app-epressplatform-row-9-col-left').classList.remove('app-epressplatform-row-9-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-9 .app-epressplatform-row-9-col-right').classList.remove('app-epressplatform-row-9-col-right-active');
    }

    increaseImageWidthRow8() {
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-8 .app-epressplatform-row-8-col-left').classList.toggle('app-epressplatform-row-8-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-8 .app-epressplatform-row-8-col-right').classList.toggle('app-epressplatform-row-8-col-right-active');

        document.querySelector('.app-section-epressplatform .app-epressplatform-row-1 .app-epressplatform-row-1-col-left').classList.remove('app-epressplatform-row-1-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-1 .app-epressplatform-row-1-col-right').classList.remove('app-epressplatform-row-1-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-2 .app-epressplatform-row-2-col-left').classList.remove('app-epressplatform-row-2-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-2 .app-epressplatform-row-2-col-right').classList.remove('app-epressplatform-row-2-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-3 .app-epressplatform-row-3-col-left').classList.remove('app-epressplatform-row-3-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-3 .app-epressplatform-row-3-col-right').classList.remove('app-epressplatform-row-3-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-4 .app-epressplatform-row-4-col-left').classList.remove('app-epressplatform-row-4-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-4 .app-epressplatform-row-4-col-right').classList.remove('app-epressplatform-row-4-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-5 .app-epressplatform-row-5-col-left').classList.remove('app-epressplatform-row-5-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-5 .app-epressplatform-row-5-col-right').classList.remove('app-epressplatform-row-5-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-6 .app-epressplatform-row-6-col-left').classList.remove('app-epressplatform-row-6-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-6 .app-epressplatform-row-6-col-right').classList.remove('app-epressplatform-row-6-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-7 .app-epressplatform-row-7-col-left').classList.remove('app-epressplatform-row-7-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-7 .app-epressplatform-row-7-col-right').classList.remove('app-epressplatform-row-7-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-9 .app-epressplatform-row-9-col-left').classList.remove('app-epressplatform-row-9-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-9 .app-epressplatform-row-9-col-right').classList.remove('app-epressplatform-row-9-col-right-active');
    }

    increaseImageWidthRow9() {
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-9 .app-epressplatform-row-9-col-left').classList.toggle('app-epressplatform-row-9-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-9 .app-epressplatform-row-9-col-right').classList.toggle('app-epressplatform-row-9-col-right-active');

        document.querySelector('.app-section-epressplatform .app-epressplatform-row-1 .app-epressplatform-row-1-col-left').classList.remove('app-epressplatform-row-1-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-1 .app-epressplatform-row-1-col-right').classList.remove('app-epressplatform-row-1-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-2 .app-epressplatform-row-2-col-left').classList.remove('app-epressplatform-row-2-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-2 .app-epressplatform-row-2-col-right').classList.remove('app-epressplatform-row-2-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-3 .app-epressplatform-row-3-col-left').classList.remove('app-epressplatform-row-3-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-3 .app-epressplatform-row-3-col-right').classList.remove('app-epressplatform-row-3-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-4 .app-epressplatform-row-4-col-left').classList.remove('app-epressplatform-row-4-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-4 .app-epressplatform-row-4-col-right').classList.remove('app-epressplatform-row-4-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-5 .app-epressplatform-row-5-col-left').classList.remove('app-epressplatform-row-5-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-5 .app-epressplatform-row-5-col-right').classList.remove('app-epressplatform-row-5-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-6 .app-epressplatform-row-6-col-left').classList.remove('app-epressplatform-row-6-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-6 .app-epressplatform-row-6-col-right').classList.remove('app-epressplatform-row-6-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-7 .app-epressplatform-row-7-col-left').classList.remove('app-epressplatform-row-7-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-7 .app-epressplatform-row-7-col-right').classList.remove('app-epressplatform-row-7-col-right-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-8 .app-epressplatform-row-8-col-left').classList.remove('app-epressplatform-row-8-col-left-active');
        document.querySelector('.app-section-epressplatform .app-epressplatform-row-8 .app-epressplatform-row-8-col-right').classList.remove('app-epressplatform-row-8-col-right-active');
    }

    hitButtonLiveDemoeSign() {
        var data = {};
        data["type"] = "click";
        data["button"] = "liveDemoeSign";

        var checkItemLocalStorage = localStorage.getItem("dataLiveDemoeSign");

        if (!checkItemLocalStorage) {
            postFeedback(`${process.env.feedbackAPI}`, data,
                function (response) {
                    console.log(response);
                    localStorage.setItem("dataLiveDemoeSign", JSON.stringify(data));
                },
                function (error) {
                    console.log("Something went wrong", error);
                });
        }
    };

    hitButtonPricingeSign() {
        var data = {};
        data["type"] = "click";
        data["button"] = "pricingeSign";

        var checkItemLocalStorage = localStorage.getItem("dataPricingeSign");

        if (!checkItemLocalStorage) {
            postFeedback(`${process.env.feedbackAPI}`, data,
                function (response) {
                    console.log(response);
                    localStorage.setItem("dataPricingeSign", JSON.stringify(data));
                },
                function (error) {
                    console.log("Something went wrong", error);
                });
        }
    };

    render() {
        const { data } = this.props;
        const introduction = data.introduction.nodes;
        const introduction_vi = data.introduction_vi.nodes;
        const row_1 = data.row_1.nodes;
        const row_1_vi = data.row_1_vi.nodes;
        const row_2 = data.row_2.nodes;
        const row_2_vi = data.row_2_vi.nodes;
        const row_3 = data.row_3.nodes;
        const row_3_vi = data.row_3_vi.nodes;
        const row_4 = data.row_4.nodes;
        const row_4_vi = data.row_4_vi.nodes;
        const row_5 = data.row_5.nodes;
        const row_5_vi = data.row_5_vi.nodes;
        const row_6 = data.row_6.nodes;
        const row_6_vi = data.row_6_vi.nodes;
        const row_7 = data.row_7.nodes;
        const row_7_vi = data.row_7_vi.nodes;
        const row_8 = data.row_8.nodes;
        const row_8_vi = data.row_8_vi.nodes;
        const row_9 = data.row_9.nodes;
        const row_9_vi = data.row_9_vi.nodes;
        const enterprise_component_1 = data.enterprise_component_1.nodes;
        const enterprise_component_1_vi = data.enterprise_component_1_vi.nodes;
        const enterprise_component_2 = data.enterprise_component_2.nodes;
        const enterprise_component_2_vi = data.enterprise_component_2_vi.nodes;
        const enterprise_component_3 = data.enterprise_component_3.nodes;
        const enterprise_component_3_vi = data.enterprise_component_3_vi.nodes;
        const enterprise_component_4 = data.enterprise_component_4.nodes;
        const enterprise_component_4_vi = data.enterprise_component_4_vi.nodes;

        var customSettingsSupportCustomerPortal = {
            dots: false,
            speed: 500,
            infinite: false,
            slidesToShow: 4,
            slidesToScroll: 4,
            responsive: [
                {
                    breakpoint: 1367,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3
                    }
                },
                {
                    breakpoint: 1279,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 1025,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        return (
            <Layout>
                <SEO title="ePress Platform" description="Ticket or Issue management system for your enterprise that run on Microsoft Teams. Helpdesk system to improve customer's satisfaction and reliable tool for issue tracking." meta={[{ name: `keywords`, content: ["collaboration tools on Microsoft Teams", "helpdesk system on Microsoft Teams", "ticket management", "issue management"] }]} pathname="/solutions/epressplatform" />
                <div className="main-content-padding">
                    <div className="app-general-seciton app-epressplatform-introduction" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                        <Container>
                            <div className="app-epressplatform-introduction-detail">
                                <Row className="app-epressplatform-introduction-row-custom">
                                    <Col className="app-epressplatform-introduction-col-custom" xs={12} md={12} lg={8}>
                                        <div className="app-epressplatform-introduction-left">
                                            {introduction.map((res) => (
                                                <div className="app-data-en" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                            ))}
                                            {introduction_vi.map((res) => (
                                                <div className="app-data-vi" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                            ))}
                                        </div>
                                    </Col>
                                    <Col xs={12} md={12} lg={4} className='app-epressplatform-introduction-col-right'></Col>
                                </Row>
                            </div>
                        </Container>
                    </div>
                    <div className="app-general-seciton app-section-epressplatform" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                        <Container>
                            <Row>
                                <Col xs={12} md={12} lg={2}></Col>
                                <Col xs={12} md={12} lg={8}>
                                    <div className="app-title">
                                        <h2 className="app-data-en">Revolutionize your entire TV production workflow with ePress Platform</h2>
                                        <h2 className="app-data-vi">Chuyển Đổi Số hệ thống Tòa Soạn Hội Tụ
                                            <br/> của bạn với ePress Platform</h2>
                                    </div>
                                </Col>
                                <Col xs={12} md={12} lg={2}></Col>
                            </Row>
                            <div className="app-epressplatform-detail">
                                <Row className="app-epressplatform-row-custom app-epressplatform-row-1">
                                    <Col xs={12} md={12} lg={12} xl={1}></Col>
                                    <Col className="app-epressplatform-col-custom app-epressplatform-row-1-col-left" xs={12} md={12} lg={12} xl={5}>
                                        {row_1.map((res) => (
                                            <div className="app-data-en app-epressplatform-left-side" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                        ))}
                                        {row_1_vi.map((res) => (
                                            <div className="app-data-vi app-epressplatform-left-side" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                        ))}
                                    </Col>
                                    <Col className="app-epressplatform-col-custom app-epressplatform-row-1-col-right" xs={12} md={12} lg={12} xl={5}>
                                        <div className="app-epressplatform-right-side">
                                            <img
                                                className="app-epressplatform-row-1-img"
                                                src="/app-epressplatform-01.svg"
                                                alt="app epressplatform row 1"
                                                onClick={this.increaseImageWidthRow1}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} md={12} lg={12} xl={1}></Col>
                                </Row>
                                <Row className="app-epressplatform-row-custom app-epressplatform-row-2">
                                    <Col xs={12} md={12} lg={12} xl={1}></Col>
                                    <Col className="app-epressplatform-col-custom app-epressplatform-row-2-col-left" xs={12} md={12} lg={12} xl={5}>
                                        <div className="app-epressplatform-left-side">
                                            <img
                                                className="app-epressplatform-row-2-img"
                                                src="/app-epressplatform-02.svg"
                                                alt="app epressplatform row 2"
                                                onClick={this.increaseImageWidthRow2}
                                            />
                                        </div>
                                    </Col>
                                    <Col className="app-epressplatform-col-custom app-epressplatform-row-2-col-right" xs={12} md={12} lg={12} xl={5}>
                                        {row_2.map((res) => (
                                            <div className="app-data-en app-epressplatform-right-side" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                        ))}
                                        {row_2_vi.map((res) => (
                                            <div className="app-data-vi app-epressplatform-right-side" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                        ))}
                                    </Col>
                                    <Col xs={12} md={12} lg={12} xl={1}></Col>
                                </Row>
                                <Row className="app-epressplatform-row-custom app-epressplatform-row-3">
                                    <Col xs={12} md={12} lg={12} xl={1}></Col>
                                    <Col className="app-epressplatform-col-custom app-epressplatform-row-3-col-left" xs={12} md={12} lg={12} xl={5}>
                                        {row_3.map((res) => (
                                            <div className="app-data-en app-epressplatform-left-side" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                        ))}
                                        {row_3_vi.map((res) => (
                                            <div className="app-data-vi app-epressplatform-left-side" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                        ))}
                                    </Col>
                                    <Col className="app-epressplatform-col-custom app-epressplatform-row-3-col-right" xs={12} md={12} lg={12} xl={5}>
                                        <div className="app-epressplatform-right-side">
                                            <img
                                                className="app-epressplatform-row-3-img"
                                                src="/app-epressplatform-03.svg"
                                                alt="app epressplatform row 3"
                                                onClick={this.increaseImageWidthRow3}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} md={12} lg={2} xl={1}></Col>
                                </Row>
                                <Row className="app-epressplatform-row-custom app-epressplatform-row-4">
                                    <Col xs={12} md={12} lg={12} xl={1}></Col>
                                    <Col className="app-epressplatform-col-custom app-epressplatform-row-4-col-left" xs={12} md={12} lg={12} xl={5}>
                                        <div className="app-epressplatform-left-side">
                                            <img
                                                className="app-epressplatform-row-4-img"
                                                src="/app-epressplatform-04.svg"
                                                alt="app epressplatform row 4"
                                                onClick={this.increaseImageWidthRow4}
                                            />
                                        </div>
                                    </Col>
                                    <Col className="app-epressplatform-col-custom app-epressplatform-row-4-col-right" xs={12} md={12} lg={12} xl={5}>
                                        {row_4.map((res) => (
                                            <div className="app-data-en app-epressplatform-right-side" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                        ))}
                                        {row_4_vi.map((res) => (
                                            <div className="app-data-vi app-epressplatform-right-side" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                        ))}
                                    </Col>
                                    <Col xs={12} md={12} lg={12} xl={1}></Col>
                                </Row>
                                <Row className="app-epressplatform-row-custom app-epressplatform-row-5">
                                    <Col xs={12} md={12} lg={12} xl={1}></Col>
                                    <Col className="app-epressplatform-col-custom app-epressplatform-row-5-col-left" xs={12} md={12} lg={12} xl={5}>
                                        {row_5.map((res) => (
                                            <div className="app-data-en app-epressplatform-left-side" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                        ))}
                                        {row_5_vi.map((res) => (
                                            <div className="app-data-vi app-epressplatform-left-side" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                        ))}
                                    </Col>
                                    <Col className="app-epressplatform-col-custom app-epressplatform-row-5-col-right" xs={12} md={12} lg={12} xl={5}>
                                        <div className="app-epressplatform-right-side">
                                            <img
                                                className="app-epressplatform-row-5-img"
                                                src="/app-epressplatform-05.svg"
                                                alt="app epressplatform row 5"
                                                onClick={this.increaseImageWidthRow5}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} md={12} lg={2} xl={1}></Col>
                                </Row>
                                <Row className="app-epressplatform-row-custom app-epressplatform-row-6">
                                    <Col xs={12} md={12} lg={12} xl={1}></Col>
                                    <Col className="app-epressplatform-col-custom app-epressplatform-row-6-col-left" xs={12} md={12} lg={12} xl={5}>
                                        <div className="app-epressplatform-left-side">
                                            <img
                                                className="app-epressplatform-row-6-img"
                                                src="/app-epressplatform-06.svg"
                                                alt="app epressplatform row 6"
                                                onClick={this.increaseImageWidthRow6}
                                            />
                                        </div>
                                    </Col>
                                    <Col className="app-epressplatform-col-custom app-epressplatform-row-6-col-right" xs={12} md={12} lg={12} xl={5}>
                                        {row_6.map((res) => (
                                            <div className="app-data-en app-epressplatform-right-side" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                        ))}
                                        {row_6_vi.map((res) => (
                                            <div className="app-data-vi app-epressplatform-right-side" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                        ))}
                                    </Col>
                                    <Col xs={12} md={12} lg={12} xl={1}></Col>
                                </Row>
                                <Row className="app-epressplatform-row-custom app-epressplatform-row-7">
                                    <Col xs={12} md={12} lg={12} xl={1}></Col>
                                    <Col className="app-epressplatform-col-custom app-epressplatform-row-7-col-left" xs={12} md={12} lg={12} xl={5}>
                                        {row_7.map((res) => (
                                            <div className="app-data-en app-epressplatform-left-side" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                        ))}
                                        {row_7_vi.map((res) => (
                                            <div className="app-data-vi app-epressplatform-left-side" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                        ))}
                                    </Col>
                                    <Col className="app-epressplatform-col-custom app-epressplatform-row-7-col-right" xs={12} md={12} lg={12} xl={5}>
                                        <div className="app-epressplatform-right-side">
                                            <img
                                                className="app-epressplatform-row-7-img"
                                                src="/app-epressplatform-07.svg"
                                                alt="app epressplatform row 7"
                                                onClick={this.increaseImageWidthRow7}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} md={12} lg={2} xl={1}></Col>
                                </Row>
                                <Row className="app-epressplatform-row-custom app-epressplatform-row-8">
                                    <Col xs={12} md={12} lg={12} xl={1}></Col>
                                    <Col className="app-epressplatform-col-custom app-epressplatform-row-8-col-left" xs={12} md={12} lg={12} xl={5}>
                                        <div className="app-epressplatform-left-side">
                                            <img
                                                className="app-epressplatform-row-8-img"
                                                src="/app-epressplatform-08.svg"
                                                alt="app epressplatform row 8"
                                                onClick={this.increaseImageWidthRow8}
                                            />
                                        </div>
                                    </Col>
                                    <Col className="app-epressplatform-col-custom app-epressplatform-row-8-col-right" xs={12} md={12} lg={12} xl={5}>
                                        {row_8.map((res) => (
                                            <div className="app-data-en app-epressplatform-right-side" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                        ))}
                                        {row_8_vi.map((res) => (
                                            <div className="app-data-vi app-epressplatform-right-side" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                        ))}
                                    </Col>
                                    <Col xs={12} md={12} lg={12} xl={1}></Col>
                                </Row>
                                <Row className="app-epressplatform-row-custom app-epressplatform-row-9">
                                    <Col xs={12} md={12} lg={12} xl={1}></Col>
                                    <Col className="app-epressplatform-col-custom app-epressplatform-row-9-col-left" xs={12} md={12} lg={12} xl={5}>
                                        {row_9.map((res) => (
                                            <div className="app-data-en app-epressplatform-left-side" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                        ))}
                                        {row_9_vi.map((res) => (
                                            <div className="app-data-vi app-epressplatform-left-side" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                        ))}
                                    </Col>
                                    <Col className="app-epressplatform-col-custom app-epressplatform-row-9-col-right" xs={12} md={12} lg={12} xl={5}>
                                        <div className="app-epressplatform-right-side">
                                            <img
                                                className="app-epressplatform-row-9-img"
                                                src="/app-epressplatform-09.svg"
                                                alt="app epressplatform row 9"
                                                onClick={this.increaseImageWidthRow9}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} md={12} lg={2} xl={1}></Col>
                                </Row>
                            </div>
                        </Container>
                    </div>
                    <div className="app-general-seciton app-section-epressplatform-enterpise-component" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                        <Container>
                            <Row>
                                <Col xs={12} md={12} lg={2}></Col>
                                <Col xs={12} md={12} lg={8}>
                                    <div className="app-title">
                                        <h2 className="app-data-en">
                                            Enterprise grade components using time-tested ePlatform
                                        </h2>
                                        <h2 className="app-data-vi">
                                        Nền tảng ePlatform trong việc xây dựng Hệ thống cho các Tổ chức, Doanh nghiệp
                                        </h2>
                                    </div>
                                </Col>
                                <Col xs={12} md={12} lg={2}></Col>
                            </Row>
                            <div className="app-section-enterpise-component-detail">
                                <Row className="app-row-top-align-center">
                                    <Col xs={12} md={12} lg={3} className="app-col-experience-1">
                                        {enterprise_component_1.map((res) => (
                                            <div className="app-data-en app-enterprise-component-1" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                        ))}
                                        {enterprise_component_1_vi.map((res) => (
                                            <div className="app-data-vi app-enterprise-component-1" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                        ))}
                                    </Col>
                                    <Col xs={12} md={12} lg={3} className="app-col-experience-2">
                                        {enterprise_component_2.map((res) => (
                                            <div className="app-data-en app-enterprise-component-2" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                        ))}
                                        {enterprise_component_2_vi.map((res) => (
                                            <div className="app-data-vi app-enterprise-component-2" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                        ))}
                                    </Col>
                                    <Col xs={12} md={12} lg={3} className="app-col-experience-3">
                                        {enterprise_component_3.map((res) => (
                                            <div className="app-data-en app-enterprise-component-3" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                        ))}
                                        {enterprise_component_3_vi.map((res) => (
                                            <div className="app-data-vi app-enterprise-component-3" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                        ))}
                                    </Col>
                                    <Col xs={12} md={12} lg={3} className="app-col-experience-4">
                                        {enterprise_component_4.map((res) => (
                                            <div className="app-data-en app-enterprise-component-4" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                        ))}
                                        {enterprise_component_4_vi.map((res) => (
                                            <div className="app-data-vi app-enterprise-component-4" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                        ))}
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                        <div className="app-spacing"></div>
                    </div>
                    <div id="app-whyusing" className="app-general-seciton app-whyusing" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                        <Container>
                            <Row>
                                <Col xs={12} md={12} lg={2}></Col>
                                <Col xs={12} md={12} lg={8}>
                                    <div className="app-title">
                                        <h2 className="app-data-en">Why using our ePress Platform</h2>
                                        <h2 className="app-data-vi">Tính năng của ePress Platform</h2>
                                    </div>
                                </Col>
                                <Col xs={12} md={12} lg={2}></Col>
                            </Row>
                            <div className="app-section-whyusing-detail">
                                {/* <iframe width="100%" height="100%" src="https://www.youtube.com/embed/tgbNymZ7vqY"></iframe> */}
                                <video className='app-epressplatform-video' controls>
                                    <source src="/ePress Platform.mp4" type="video/mp4"/>
                                </video>
                            </div>
                        </Container>
                        <div className="app-spacing"></div>
                    </div>
                    <div className="app-general-seciton app-contact app-data-vi" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                        <Container>
                            <Row>
                                <Col xs={12} md={12} lg={12}>
                                    <h2 className='app-contact-title'>Thông tin liên hệ</h2>
                                    <h4>CÔNG TY TNHH MTV HỆ THỐNG APPVITY VIETNAM</h4>
                                    <h6>Địa chỉ: 46 Bạch Đằng, Phường 2, Quận Tân Bình, Thành Phố Hồ Chí Minh, Việt Nam</h6>
                                    <h6>Số điện thoại: (028) 35474562</h6>
                                    <h6>Email: info.epress@appvity.com</h6>
                                    <h6>Liên hệ hỗ trợ chi tiết: 0901058059 (Ms. Dung)</h6>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default epressplatform

export const query = graphql`
    query ePressPlatformPage {
        introduction: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "epressplatform_introduction" } } }
        ) {
            nodes {
                html
                id
            }
        }
        introduction_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "epressplatform_introduction_vi" } } }
        ) {
            nodes {
                html
                id
            }
        }
		row_1: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "epressplatform_row_1" } } }
        ) {
            nodes {
                html
                id
            }
        }
		row_1_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "epressplatform_row_1_vi" } } }
        ) {
            nodes {
                html
                id
            }
        }
        row_2: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "epressplatform_row_2" } } }
        ) {
            nodes {
                html
                id
            }
        }
        row_2_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "epressplatform_row_2_vi" } } }
        ) {
            nodes {
                html
                id
            }
        }
        row_3: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "epressplatform_row_3" } } }
        ) {
            nodes {
                html
                id
            }
        }
        row_3_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "epressplatform_row_3_vi" } } }
        ) {
            nodes {
                html
                id
            }
        }
        row_4: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "epressplatform_row_4" } } }
        ) {
            nodes {
                html
                id
            }
        }
        row_4_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "epressplatform_row_4_vi" } } }
        ) {
            nodes {
                html
                id
            }
        }
        row_5: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "epressplatform_row_5" } } }
        ) {
            nodes {
                html
                id
            }
        }
        row_5_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "epressplatform_row_5_vi" } } }
        ) {
            nodes {
                html
                id
            }
        }
        row_6: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "epressplatform_row_6" } } }
        ) {
            nodes {
                html
                id
            }
        }
        row_6_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "epressplatform_row_6_vi" } } }
        ) {
            nodes {
                html
                id
            }
        }
        row_7: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "epressplatform_row_7" } } }
        ) {
            nodes {
                html
                id
            }
        }
        row_7_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "epressplatform_row_7_vi" } } }
        ) {
            nodes {
                html
                id
            }
        }
        row_8: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "epressplatform_row_8" } } }
        ) {
            nodes {
                html
                id
            }
        }
        row_8_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "epressplatform_row_8_vi" } } }
        ) {
            nodes {
                html
                id
            }
        }
        row_9: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "epressplatform_row_9" } } }
        ) {
            nodes {
                html
                id
            }
        }
        row_9_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "epressplatform_row_9_vi" } } }
        ) {
            nodes {
                html
                id
            }
        }
        enterprise_component_1: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "epressplatform_enterprise_component_1" } } }
        ) {
            nodes {
                html
                id
            }
        }
        enterprise_component_1_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "epressplatform_enterprise_component_1_vi" } } }
        ) {
            nodes {
                html
                id
            }
        }
        enterprise_component_2: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "epressplatform_enterprise_component_2" } } }
        ) {
            nodes {
                html
                id
            }
        }
        enterprise_component_2_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "epressplatform_enterprise_component_2_vi" } } }
        ) {
            nodes {
                html
                id
            }
        }
        enterprise_component_3: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "epressplatform_enterprise_component_3" } } }
        ) {
            nodes {
                html
                id
            }
        }
        enterprise_component_3_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "epressplatform_enterprise_component_3_vi" } } }
        ) {
            nodes {
                html
                id
            }
        }
        enterprise_component_4: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "epressplatform_enterprise_component_4" } } }
        ) {
            nodes {
                html
                id
            }
        }
        enterprise_component_4_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "epressplatform_enterprise_component_4_vi" } } }
        ) {
            nodes {
                html
                id
            }
        }
    }
`;
